import React from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";
import { motion } from "framer-motion";
import { FaWallet, FaSignOutAlt } from "react-icons/fa";

const ConnectButton: React.FC = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const handleClick = () => {
    if (isConnected) {
      disconnect();
    } else {
      open();
    }
  };

  return (
    <motion.button
      onClick={handleClick}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`
        relative group px-6 py-2.5 rounded-xl font-medium text-sm sm:text-base
        transition-all duration-200 shadow-md hover:shadow-lg
        ${
          isConnected
            ? "bg-gradient-to-r from-green-500 to-teal-500 text-white"
            : "bg-gradient-to-r from-purple-500 to-orange-500 text-white"
        }
      `}
    >
      <motion.div
        className="absolute inset-0 rounded-xl bg-black opacity-0 group-hover:opacity-10 transition-opacity"
        initial={false}
        animate={{ opacity: 0 }}
        whileHover={{ opacity: 0.1 }}
      />

      <div className="flex items-center justify-center gap-2">
        {isConnected ? (
          <>
            <span className="flex items-center gap-2">
              <span className="w-2 h-2 rounded-full bg-green-300 animate-pulse" />
              {`${address?.slice(0, 6)}...${address?.slice(-4)}`}
            </span>
            <FaSignOutAlt className="text-white/80" />
          </>
        ) : (
          <>
            <FaWallet className="text-white/90" />
            <span>Connect Wallet</span>
          </>
        )}
      </div>

      {/* Gradient Border Effect */}
      <div className="absolute -inset-[1px] bg-gradient-to-r from-white/20 to-white/20 rounded-xl opacity-0 group-hover:opacity-100 blur-sm transition-opacity" />
    </motion.button>
  );
};

export default ConnectButton;
