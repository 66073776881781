import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaXTwitter, FaGithub, FaDiscord } from "react-icons/fa6";

const Footer: React.FC = () => {
  const socialLinks = [
    {
      icon: <FaXTwitter className="w-5 h-5" />,
      href: "https://x.com/iAssetsOrg",
      label: "X",
    },
    {
      icon: <FaGithub className="w-5 h-5" />,
      href: "https://github.com/iassetsorg",
      label: "Github",
    },
    {
      icon: <FaDiscord className="w-5 h-5" />,
      href: "http://discord.gg/xM7SkkTEAG",
      label: "Discord",
    },
  ];

  const tokenLinks = [
    {
      href: "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x12Da2f2761038486271C99DA7e0FB4413e2B5E38",
      label: "NBM (BSC)",
    },
    {
      href: "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x6b471d5ab9f3d92a600e7d49a0b135bf6d4c6a5b",
      label: "ASSET (BSC)",
    },
    {
      href: "https://www.saucerswap.finance/swap/HBAR/0.0.1991880",
      label: "ASSET (HTS)",
    },
  ];

  const nftLinks = [
    {
      href: "https://sentx.io/nft-marketplace/0.0.3844404",
      label: "iBird Gen1",
    },
  ];

  return (
    <footer className="relative bg-gradient-to-b from-gray-900 to-black text-white py-16 overflow-hidden">
      {/* Animated Background */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 2 }}
        className="absolute inset-0"
        style={{
          backgroundImage:
            "radial-gradient(circle at 50% 50%, #FF6B6B 1px, transparent 1px)",
          backgroundSize: "50px 50px",
        }}
      />

      {/* Floating Elements */}
      <motion.div
        animate={{ y: [0, -20, 0] }}
        transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
        className="absolute left-10 top-32 w-32 h-32 bg-gradient-to-r from-purple-400/10 to-blue-400/10 rounded-full blur-xl"
      />
      <motion.div
        animate={{ y: [0, 20, 0] }}
        transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
        className="absolute right-10 bottom-32 w-32 h-32 bg-gradient-to-r from-orange-400/10 to-pink-400/10 rounded-full blur-xl"
      />

      <div className="relative container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Brand Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-orange-400">
              iAssets
            </h2>
            <p className="text-gray-400">
              Building the future of decentralized social media.
            </p>
          </motion.div>

          {/* Social Links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-semibold">Connect</h3>
            <div className="flex flex-col space-y-2">
              {socialLinks.map((link, index) => (
                <motion.a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-gray-400 hover:text-white transition-colors duration-300"
                  whileHover={{ x: 5 }}
                >
                  <span className="mr-2">{link.icon}</span>
                  {link.label}
                </motion.a>
              ))}
            </div>
          </motion.div>

          {/* Token Links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-semibold">Tokens</h3>
            <div className="flex flex-col space-y-2">
              {tokenLinks.map((link, index) => (
                <motion.a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors duration-300"
                  whileHover={{ x: 5 }}
                >
                  {link.label}
                </motion.a>
              ))}
            </div>
          </motion.div>

          {/* NFT & Legal Links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-semibold">NFTs & Legal</h3>
            <div className="flex flex-col space-y-2">
              {nftLinks.map((link, index) => (
                <motion.a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors duration-300"
                  whileHover={{ x: 5 }}
                >
                  {link.label}
                </motion.a>
              ))}
              <Link
                to="/TermsOfService"
                className="text-gray-400 hover:text-white transition-colors duration-300"
              >
                Terms of Service
              </Link>
            </div>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.8 }}
          className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400"
        >
          © {new Date().getFullYear()} iAssets. All rights reserved.
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
