import React from "react";
import { motion } from "framer-motion";
import { FaUsers, FaGithub, FaHashtag } from "react-icons/fa";

const Ibird: React.FC = () => {
  const features = [
    {
      icon: <FaUsers className="w-8 h-8" />,
      title: "Community-Driven",
      description:
        "Governed democratically by its users, iBird evolves with the community at its heart. Your voice shapes its future.",
      link: "https://discord.gg/xM7SkkTEAG",
      linkText: "Join The Community",
    },
    {
      icon: <FaGithub className="w-8 h-8" />,
      title: "Open-Source",
      description:
        "With transparent, open-source code, iBird fosters trust and continuous innovation, free from hidden agendas.",
      link: "https://github.com/iassetsorg/Project-Harriet",
      linkText: "Start Building Freedom",
    },
    {
      icon: <FaHashtag className="w-8 h-8" />,
      title: "Hedera-Powered",
      description:
        "Harnessing Hedera's advanced technology, iBird offers a fast, secure, and equitable social media experience.",
      link: "https://hedera.com/",
      linkText: "Learn about Hedera",
    },
  ];

  return (
    <div className="relative w-full py-32 overflow-hidden bg-gradient-to-b from-gray-900 to-gray-800">
      {/* Background Image with Overlay */}
      <div
        className="absolute inset-0 opacity-30"
        style={{
          backgroundImage: "url('/data.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />

      {/* Floating Elements */}
      <motion.div
        animate={{ y: [0, -20, 0] }}
        transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
        className="absolute left-10 top-32 w-32 h-32 bg-gradient-to-r from-blue-400/20 to-purple-400/20 rounded-full blur-xl"
      />
      <motion.div
        animate={{ y: [0, 20, 0] }}
        transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
        className="absolute right-10 bottom-32 w-32 h-32 bg-gradient-to-r from-orange-400/20 to-pink-400/20 rounded-full blur-xl"
      />

      <div className="relative container mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-20"
        >
          <h2 className="text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400 mb-6">
            Welcome to iBird
          </h2>
          <p className="text-2xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
            iBird is a revolutionary social media platform built on Hedera by
            iAssets. It leverages Hashgraph technology for unparalleled speed,
            security, and innovation.
          </p>
        </motion.div>

        <div className="grid gap-8 lg:grid-cols-3 sm:grid-cols-1">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="group"
            >
              <div className="h-full bg-white/10 backdrop-blur-sm rounded-3xl p-8 hover:bg-white/15 transition-all duration-300 border border-white/10">
                <div className="flex items-center mb-6">
                  <div className="p-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-2xl text-white">
                    {feature.icon}
                  </div>
                  <h3 className="text-2xl font-bold text-white ml-4">
                    {feature.title}
                  </h3>
                </div>

                <p className="text-gray-300 mb-8 text-lg leading-relaxed">
                  {feature.description}
                </p>

                <motion.a
                  href={feature.link}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="inline-flex items-center px-6 py-3 text-white bg-gradient-to-r from-blue-500 to-purple-500 rounded-full hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {feature.linkText}
                  <motion.span
                    className="ml-2"
                    animate={{ x: [0, 5, 0] }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                  >
                    →
                  </motion.span>
                </motion.a>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ibird;
