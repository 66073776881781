import React from "react";
import { motion } from "framer-motion";
import {
  FaServer,
  FaDesktop,
  FaDatabase,
  FaUserLock,
  FaCode,
  FaPaintBrush,
} from "react-icons/fa";

const HowWorks: React.FC = () => {
  const features = {
    backend: [
      {
        icon: <FaServer className="w-8 h-8" />,
        title: "Hedera Consensus Service",
        description:
          "Keeps a secure and accurate record of all events and actions, like a diary that timestamps everything.",
      },
      {
        icon: <FaDatabase className="w-8 h-8" />,
        title: "Message Recording",
        description:
          "Records messages, likes, comments, and other activities on the Hedera network, ensuring everything is transparent and tamper-proof.",
      },
      {
        icon: <FaUserLock className="w-8 h-8" />,
        title: "Token Service",
        description:
          "Creates unique digital profiles for users in the form of NFTs (Non-Fungible Tokens), like digital ID cards.",
      },
    ],
    frontend: [
      {
        icon: <FaDesktop className="w-8 h-8" />,
        title: "User Interface",
        description:
          "Sleek and user-friendly design to make your experience smooth and enjoyable.",
      },
      {
        icon: <FaCode className="w-8 h-8" />,
        title: "Smart Features",
        description:
          "Efficiently reads and writes data to Hedera, ensuring seamless real-time interactions.",
      },
      {
        icon: <FaPaintBrush className="w-8 h-8" />,
        title: "Live Updates",
        description:
          "Continuously reads data from Hedera network to provide you with the most up-to-date messages and interactions.",
      },
    ],
  };

  return (
    <div className="relative w-full py-32 overflow-hidden bg-gradient-to-b from-[#FDF8F3] to-gray-50">
      {/* Background Elements */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 2 }}
        className="absolute inset-0"
        style={{
          backgroundImage:
            "radial-gradient(circle at 50% 50%, #FF6B6B 1px, transparent 1px)",
          backgroundSize: "50px 50px",
        }}
      />

      {/* Floating Elements */}
      <motion.div
        animate={{ y: [0, -20, 0] }}
        transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
        className="absolute left-10 top-32 w-32 h-32 bg-gradient-to-r from-orange-400/30 to-yellow-400/30 rounded-full blur-xl"
      />
      <motion.div
        animate={{ y: [0, 20, 0] }}
        transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
        className="absolute right-10 bottom-32 w-32 h-32 bg-gradient-to-r from-teal-400/30 to-blue-400/30 rounded-full blur-xl"
      />

      <div className="relative container mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-20"
        >
          <h2 className="text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-orange-500 mb-6">
            How does iBird work?
          </h2>
          <p className="text-2xl text-gray-600 max-w-2xl mx-auto">
            A powerful combination of Hedera technology and modern web
            development
          </p>
        </motion.div>

        <div className="grid gap-12 lg:grid-cols-2">
          {/* Backend Section */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="bg-white/70 backdrop-blur-sm rounded-3xl p-10 shadow-xl hover:shadow-2xl transition-all duration-500"
          >
            <h3 className="text-4xl font-bold text-gray-800 mb-8 flex items-center">
              <FaServer className="mr-4 text-orange-500" />
              Back-End
            </h3>
            <div className="space-y-8">
              {features.backend.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-6 group"
                >
                  <div className="flex-shrink-0 p-3 sm:p-4 bg-orange-100 rounded-2xl text-orange-500 group-hover:bg-orange-200 transition-colors duration-300">
                    {React.cloneElement(feature.icon as React.ReactElement, {
                      className: "w-6 h-6 sm:w-8 sm:h-8",
                    })}
                  </div>
                  <div className="text-center sm:text-left">
                    <h4 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
                      {feature.title}
                    </h4>
                    <p className="text-base sm:text-lg text-gray-600 leading-relaxed">
                      {feature.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Frontend Section */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="bg-white/70 backdrop-blur-sm rounded-3xl p-6 sm:p-10 shadow-xl hover:shadow-2xl transition-all duration-500"
          >
            <h3 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-6 sm:mb-8 flex items-center justify-center sm:justify-start">
              <FaDesktop className="mr-4 text-blue-500" />
              Front-End
            </h3>
            <div className="space-y-6 sm:space-y-8">
              {features.frontend.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-6 group"
                >
                  <div className="flex-shrink-0 p-3 sm:p-4 bg-blue-100 rounded-2xl text-blue-500 group-hover:bg-blue-200 transition-colors duration-300">
                    {React.cloneElement(feature.icon as React.ReactElement, {
                      className: "w-6 h-6 sm:w-8 sm:h-8",
                    })}
                  </div>
                  <div className="text-center sm:text-left">
                    <h4 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
                      {feature.title}
                    </h4>
                    <p className="text-base sm:text-lg text-gray-600 leading-relaxed">
                      {feature.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
