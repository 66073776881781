import React, { useState } from "react";
import {
  FaRocket,
  FaCode,
  FaExpandAlt,
  FaUsers,
  FaGlobe,
  FaUserCircle,
  FaImage,
  FaMoneyBillWave,
  FaTools,
  FaChartLine,
  FaMobileAlt,
  FaVoteYea,
  FaUserFriends,
} from "react-icons/fa";
import { SiCreatereactapp } from "react-icons/si";
import { GiBurningEmbers, GiStumpRegrowth, GiTreeGrowth } from "react-icons/gi";
import { GrUpdate } from "react-icons/gr";
import { motion } from "framer-motion";

type RoadmapItem = {
  version: string;
  title: string;
  icon: JSX.Element;
  features: string[];
};

type RoadmapData = {
  iBird: RoadmapItem[];
  iAssets: RoadmapItem[];
};

const Roadmap: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"iBird" | "iAssets">("iBird");
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);

  const roadmapData: RoadmapData = {
    iBird: [
      {
        version: "v0.0.1",
        title: "Threads",
        icon: <FaRocket />,
        features: [
          "🟢 Connect wallet",
          "🟢 Create threads",
          "🟢 Read thread's data",
          "🟢 Send message to threads",
        ],
      },
      {
        version: "v0.0.2",
        title: "Interactions",
        icon: <FaUsers />,
        features: [
          "🟢 Reply to messages",
          "🟢 Like",
          "🟢 Dislike",
          "🟢 Write Comments",
        ],
      },
      {
        version: "v0.0.3",
        title: "Explore Feed",
        icon: <FaGlobe />,
        features: [
          "🟢 Directory for all threads",
          "🟢 Explore Topic: 0.0.3946144",
          "🟢 Users can send their threads to the Explore Feed",
        ],
      },
      {
        version: "v0.0.4",
        title: "Profile & Planet",
        icon: <FaUserCircle />,
        features: [
          "🟢 Create user Profile NFT",
          "🟢 Create user Profile Topic",
          "🟢 Save Profile Topic in Profile NFT",
          "🟢 Create user Threads",
          "🟢 Save user Threads in Profile Topic",
          "🟢 Planet Topic: 0.0.4320596",
        ],
      },
      {
        version: "v0.0.5",
        title: "Media Support",
        icon: <FaImage />,
        features: [
          "🟢 Save media files on IPFS",
          "🟢 Send media files with messages",
        ],
      },
      {
        version: "v0.0.6",
        title: "Tipping Support",
        icon: <FaMoneyBillWave />,
        features: [
          "🟢 Tip HBAR, ASSET",
          "🟢 Tip SAUCE, GRELF, USDC",
          "🟢 Tip DOVU, SAUCEINU, JAM",
          "🟢 Tip HSUITE, BSL, BULLBAR",
        ],
      },
      {
        version: "v0.0.7",
        title: "UPGRADO",
        icon: <FaTools />,
        features: [
          "🟢 Hash Link #",
          "🟢 Dark & light theme",
          "🟢 Universal Topic",
          "🟢 Interactive polls",
          "🟢 Users profile",
        ],
      },
      {
        version: "v0.0.8",
        title: "Reward & Analyze",
        icon: <FaChartLine />,
        features: ["🟢 Reward engine", "🟢 System Analytics"],
      },
      {
        version: "v0.0.9",
        title: "Applications",
        icon: <FaMobileAlt />,
        features: [
          "🟢 Date & Time Stamps",
          "🟢 Character Counter",
          "🟢 Link, #, $ Reader",
          "🟢 Explorer Infinite Scrolling",
          "🟢 Wallet Connecet",
          "🟢 IOS Application",
          "🟢 Android Application",
          "🟢 New Logo",
        ],
      },
      {
        version: "v0.1.0",
        title: "Community Governance",
        icon: <FaVoteYea />,
        features: [
          "🟠 List Android Application on Android Stores",
          "🟠 List IOS Application on IOS Stores",
          "🟠 Followers",
          "🟠 Channels",
          "🟠 Private Messages",
          "🟠 Special Features for iBird Gen 1 NFT Holders",
          "🟠 iBird Gen 1: The First Flight, Holders propose and vote on key platform updates",
        ],
      },
    ],
    iAssets: [
      {
        version: "Stage 1",
        title: "Creation",
        icon: <SiCreatereactapp />,
        features: [
          "🟢 Creation of NFTBlackMarket",
          "🟢 NFTBlackMarket Website Launch",
          "🟢 Creation of NBM (BSC) Token",
          "🟢 NBM Token Listed on PancakeSwap",
          "🟢 NBM Liquidity Locked Forever",
          "🟢 NBM Smartcontract Audited by TechRate",
          "🟢 Launch of NFT Marketplace",
        ],
      },
      {
        version: "Stage 2",
        title: "Rebrandibg",
        icon: <GrUpdate />,
        features: [
          "🟢 NFTBlackMarket Rebranded to iAssets",
          "🟢 NBM (BSC)Token Rebranded to ASSET (BSC)",
          "🟢 NFT Marketplace Rebranded as iAssets.market",
          "🟢 iAssets.org Website Launch",
          "🟢 NBM (BSC) to ASSET (BSC) Converter",
        ],
      },
      {
        version: "Stage 3",
        title: "Expansion",
        icon: <FaExpandAlt />,
        features: [
          "🟢 ASSET Token Listed on IndoEx",
          "🟢 Launch of iAssets.Social (Social Media) on BSC",
          "🟢 Launch of iAssets.Marketing",
        ],
      },
      {
        version: "Stage 4",
        title: "Destruction",
        icon: <GiBurningEmbers />,
        features: [
          "🟢 iAssets.Market (NFT Platform) deprecated",
          "🟢 iAssets.Marketing deprecated",
          "🟢 NBM (BSC) Token deprecated",
          "🟢 ASSET (BSC) Token deprecated",
          "🟢 iAssets.Social on BSC deprecated",
        ],
      },
      {
        version: "Stage 5",
        title: "Growth",
        icon: <GiStumpRegrowth />,
        features: [
          "🟢 Migration to Hedera",
          "🟢 Launch iAssets.Social on Hedera",
          "🟢 Creation of ASSET(HTS) Token on Hedera",
          "🟢 NBM (BSC), ASSET(BSC )to ASSET(HTS) Converter",
          "🟢 Rebrand iAssets.Social to iBird.io",
          "🟢 The project became Open-Source & Community-Driven",
          "🟢 Launch of iBird.community",
          "🟢 Redesign iAssets.org",
          "🟢 Transfer iBird.community website to iBird.io",
        ],
      },
      {
        version: "Stage 6",
        title: "Community",
        icon: <GiTreeGrowth />,
        features: [
          "🟠 100 Users",
          "🟠 1,000 Users",
          "🟠 10,000 Users",
          "🟠 100,000 Users",
          "🟠 1,000,000 Users",
          "🟠 10,000,000 Users",
          "🟠 100,000,000 Users",
          "🟠 1,000,000,000 Users",
        ],
      },
    ],
  };

  return (
    <div className="relative w-full py-32 overflow-hidden bg-gradient-to-b from-gray-50 to-white">
      {/* Enhanced background elements */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.08 }}
        transition={{ duration: 2 }}
        className="absolute inset-0"
        style={{
          backgroundImage:
            "radial-gradient(circle at 50% 50%, #FF6B6B 1.5px, transparent 1.5px)",
          backgroundSize: "60px 60px",
        }}
      />

      {/* Enhanced floating elements with more subtle animations */}
      <motion.div
        animate={{
          y: [0, -20, 0],
          scale: [1, 1.1, 1],
          opacity: [0.6, 0.8, 0.6],
        }}
        transition={{ duration: 6, repeat: Infinity, ease: "easeInOut" }}
        className="absolute left-10 top-32 w-40 h-40 bg-gradient-to-r from-orange-400/20 to-yellow-400/20 rounded-full blur-2xl"
      />
      <motion.div
        animate={{ y: [0, 20, 0] }}
        transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
        className="absolute right-10 bottom-32 w-32 h-32 bg-gradient-to-r from-teal-400/30 to-blue-400/30 rounded-full blur-xl"
      />

      <div className="relative container mx-auto px-6">
        {/* Enhanced title animation */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          viewport={{ once: true }}
          className="text-center mb-14"
        >
          <h2 className="text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 mb-6 hover:scale-105 transition-transform duration-300">
            Our Journey
          </h2>
        </motion.div>

        {/* Enhanced tab buttons */}
        <div className="flex justify-center mb-12">
          {["iBird", "iAssets"].map((tab) => (
            <motion.button
              key={tab}
              whileHover={{ scale: 1.05, y: -2 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveTab(tab as "iBird" | "iAssets")}
              className={`px-8 py-3 mx-2 text-xl font-semibold rounded-full transition-all duration-300 ${
                activeTab === tab
                  ? "bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-white shadow-lg"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
            >
              {tab}
            </motion.button>
          ))}
        </div>

        {/* Enhanced grid layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
          {roadmapData[activeTab].map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              onHoverStart={() => setHoveredCard(index)}
              onHoverEnd={() => setHoveredCard(null)}
              className={`bg-white/80 backdrop-blur-sm p-8 rounded-3xl shadow-xl transition-all duration-500
                ${
                  hoveredCard === index
                    ? "scale-[1.02] shadow-2xl bg-white/90"
                    : "hover:shadow-xl"
                }`}
            >
              <div className="flex items-center mb-6">
                <motion.div
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.5 }}
                  className="p-4 bg-gradient-to-r from-[#FF6B6B] via-[#FF8E53] to-[#FFA33C] rounded-2xl text-white text-4xl shadow-lg"
                >
                  {item.icon}
                </motion.div>
                <div className="ml-4">
                  <span className="text-lg font-semibold text-[#FF6B6B]">
                    {item.version}
                  </span>
                  <h3 className="text-2xl font-bold text-gray-800">
                    {item.title}
                  </h3>
                </div>
              </div>

              <ul className="space-y-3">
                {item.features.map((feature, i) => (
                  <motion.li
                    key={i}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3, delay: i * 0.05 }}
                    viewport={{ once: true }}
                    whileHover={{ x: 5 }}
                    className="flex items-center text-gray-700 hover:text-gray-900"
                  >
                    <span
                      className={`w-3 h-3 rounded-full mr-3 flex-shrink-0 ${
                        feature.startsWith("🟢")
                          ? "bg-green-500"
                          : "bg-yellow-500"
                      }`}
                    />
                    <span className="text-lg">{feature.slice(2)}</span>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
