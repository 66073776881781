import React, { useState, useEffect } from "react";
import {
  FaUsers,
  FaComments,
  FaPoll,
  FaChartLine,
  FaArrowUp,
  FaArrowDown,
} from "react-icons/fa";
import { BiMessageDetail, BiRepost } from "react-icons/bi";
import {
  MdAllInclusive,
  MdCalendarToday,
  MdDateRange,
  MdViewWeek,
  MdOutlineCalendarMonth,
  MdViewQuilt,
} from "react-icons/md";
import { IoStatsChart } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";

interface AnalyticsData {
  growth: {
    [key: string]: {
      messages: {
        current_count: number;
        previous_count: number;
        growth_percentage: number;
      };
      users: {
        current_count: number;
        previous_count: number;
        growth_percentage: number;
      };
      posts: {
        current_count: number;
        previous_count: number;
        growth_percentage: number;
      };
      threads: {
        current_count: number;
        previous_count: number;
        growth_percentage: number;
      };
      polls: {
        current_count: number;
        previous_count: number;
        growth_percentage: number;
      };
      reposts: {
        current_count: number;
        previous_count: number;
        growth_percentage: number;
      };
    };
  };
}

const Analytics: React.FC = () => {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState("all_time");

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await fetch("/analytics.json");
        const data = await response.json();
        setAnalyticsData(data);
        setLoading(false);
      } catch (err) {
        setError(err as Error);
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, []);

  const periods = [
    {
      id: "all_time",
      label: "All Time",
      icon: <MdAllInclusive className="text-xl" />,
    },
    {
      id: "year",
      label: "Year",
      icon: <MdDateRange className="text-xl" />,
    },
    {
      id: "quarter",
      label: "Quarter",
      icon: <MdViewQuilt className="text-xl" />,
    },
    {
      id: "month",
      label: "Month",
      icon: <MdOutlineCalendarMonth className="text-xl" />,
    },
    { id: "week", label: "Week", icon: <MdViewWeek className="text-xl" /> },
    {
      id: "today",
      label: "Today",
      icon: <MdCalendarToday className="text-xl" />,
    },
  ];

  const getCurrentPeriodData = () => {
    if (!analyticsData?.growth || !selectedPeriod) return null;
    return analyticsData.growth[selectedPeriod];
  };

  const periodData = getCurrentPeriodData();

  const mainStats = [
    {
      icon: <BiMessageDetail className="w-6 h-6" />,
      label: "Messages",
      value: periodData?.messages?.current_count || 0,
      previousValue: periodData?.messages?.previous_count || 0,
      growth: periodData?.messages?.growth_percentage || 0,
      description: "Active Conversations",
    },
    {
      icon: <FaUsers className="w-6 h-6" />,
      label: "Users",
      value: periodData?.users?.current_count || 0,
      previousValue: periodData?.users?.previous_count || 0,
      growth: periodData?.users?.growth_percentage || 0,
      description: "Platform Members",
    },
  ];

  const secondaryStats = [
    {
      icon: <FaComments className="w-5 h-5" />,
      label: "Posts",
      value: periodData?.posts?.current_count || 0,
      previousValue: periodData?.posts?.previous_count || 0,
      trend: periodData?.posts?.growth_percentage || 0,
    },
    {
      icon: <FaChartLine className="w-5 h-5" />,
      label: "Threads",
      value: periodData?.threads?.current_count || 0,
      previousValue: periodData?.threads?.previous_count || 0,
      trend: periodData?.threads?.growth_percentage || 0,
    },
    {
      icon: <FaPoll className="w-5 h-5" />,
      label: "Polls",
      value: periodData?.polls?.current_count || 0,
      previousValue: periodData?.polls?.previous_count || 0,
      trend: periodData?.polls?.growth_percentage || 0,
    },
    {
      icon: <BiRepost className="w-5 h-5" />,
      label: "Reposts",
      value: periodData?.reposts?.current_count || 0,
      previousValue: periodData?.reposts?.previous_count || 0,
      trend: periodData?.reposts?.growth_percentage || 0,
    },
  ];

  const renderGrowthIndicator = (value: string | number) => {
    const isMultiplier = typeof value === "string" && value.endsWith("x");
    const numericValue = isMultiplier ? parseFloat(value) : Number(value);
    const isPositive = isMultiplier || numericValue >= 0;
    const isZero = numericValue === 0;

    if (isZero) return null;

    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="relative group cursor-pointer"
      >
        <div
          className={`
          flex items-center gap-2 px-3 py-1.5 rounded-lg
          backdrop-blur-sm relative z-10
          ${
            isPositive
              ? "bg-cyan-950 text-cyan-200 border border-cyan-500/20 hover:bg-cyan-900/80"
              : "bg-violet-950 text-violet-200 border border-violet-500/20 hover:bg-violet-900/80"
          }
          transition-colors duration-300
        `}
        >
          <div
            className={`
            flex items-center justify-center w-6 h-6 rounded-full
            ${isPositive ? "bg-cyan-600/20" : "bg-violet-600/20"}
          `}
          >
            {isPositive ? (
              <FaArrowUp className="w-3 h-3 group-hover:animate-bounce" />
            ) : (
              <FaArrowDown className="w-3 h-3 group-hover:animate-bounce" />
            )}
          </div>
          <span className="text-sm font-semibold">
            {isMultiplier ? value : `${Math.abs(numericValue).toFixed(1)}%`}
          </span>
        </div>

        <div
          className={`
          absolute inset-0 -z-10 rounded-lg opacity-0 group-hover:opacity-100 blur-md
          ${isPositive ? "bg-cyan-500/20" : "bg-violet-500/20"}
          transition-opacity duration-300
        `}
        />
      </motion.div>
    );
  };

  const renderSparkline = (value: string | number) => {
    const isMultiplier = typeof value === "string" && value.endsWith("x");
    const numericValue = isMultiplier ? parseFloat(value) : Number(value);
    const isZero = numericValue === 0;
    const isPositive = isMultiplier || numericValue >= 0;

    if (isZero) {
      return (
        <motion.svg
          width={120}
          height={32}
          className="text-white/20"
          whileHover={{ scale: 1.05 }}
        >
          <line
            x1="0"
            y1="16"
            x2="120"
            y2="16"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeDasharray="4 4"
            className="animate-dash"
          />
        </motion.svg>
      );
    }

    const height = 32;
    const width = 120;
    const pointCount = 12; // Increased number of points for smoother curve

    // Generate points with improved curve generation
    const points = Array.from({ length: pointCount }, (_, i) => {
      const progress = i / (pointCount - 1);
      const trend = isMultiplier ? Math.log(numericValue) : numericValue / 100;

      // Enhanced volatility calculation
      const baseVolatility = Math.min(Math.abs(trend) * 0.3, 0.5);
      const progressiveVolatility =
        baseVolatility * (1 - Math.abs(0.5 - progress));
      const randomFactor = (Math.random() - 0.5) * progressiveVolatility;

      const y =
        height / 2 +
        (isPositive
          ? -trend * height * 0.4 * Math.pow(progress, 1.2) // Added curve acceleration
          : trend * height * 0.4 * Math.pow(progress, 1.2)) +
        randomFactor * height;

      return {
        x: (i * width) / (pointCount - 1),
        y: Math.max(2, Math.min(height - 2, y)),
      };
    });

    // Smoother curve generation with enhanced control points
    const line = points.reduce((acc, point, i) => {
      if (i === 0) return `M ${point.x},${point.y}`;
      const prev = points[i - 1];
      const cp1x = prev.x + (point.x - prev.x) * 0.5;
      const cp2x = point.x - (point.x - prev.x) * 0.5;
      return `${acc} C ${cp1x},${prev.y} ${cp2x},${point.y} ${point.x},${point.y}`;
    }, "");

    // Calculate area path for gradient
    const areaPath = `${line} L ${width},${height} L 0,${height} Z`;

    return (
      <motion.div whileHover={{ scale: 1.05 }} className="relative group">
        <svg
          width={width}
          height={height}
          className={`
            transition-all duration-300 cursor-pointer
            ${
              isMultiplier || numericValue >= 0
                ? "text-cyan-500/50 hover:text-cyan-400"
                : "text-violet-500/50 hover:text-violet-400"
            }
          `}
        >
          {/* Gradient definitions */}
          <defs>
            <linearGradient
              id={`gradient-${isPositive ? "positive" : "negative"}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="0%"
                className={`${
                  isPositive ? "text-cyan-400" : "text-violet-400"
                }`}
                stopOpacity="0.2"
              />
              <stop
                offset="100%"
                className={`${
                  isPositive ? "text-cyan-400" : "text-violet-400"
                }`}
                stopOpacity="0"
              />
            </linearGradient>
          </defs>

          {/* Area fill with gradient */}
          <path
            d={areaPath}
            fill={`url(#gradient-${isPositive ? "positive" : "negative"})`}
            className="transition-opacity duration-300 opacity-0 group-hover:opacity-100"
          />

          {/* Main line */}
          <path
            d={line}
            fill="none"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            className="transition-all duration-300"
          />

          {/* Data points */}
          {points.map((point, index) => (
            <motion.circle
              key={index}
              cx={point.x}
              cy={point.y}
              r={index === points.length - 1 ? 2 : 0}
              className={`
                fill-current 
                ${
                  index === points.length - 1
                    ? "opacity-100"
                    : "opacity-0 group-hover:opacity-100"
                }
              `}
              whileHover={{ scale: 1.5 }}
              transition={{ duration: 0.2 }}
            />
          ))}

          {/* Animated highlight on hover */}
          <motion.circle
            cx={points[points.length - 1].x}
            cy={points[points.length - 1].y}
            r={4}
            className="fill-transparent stroke-current opacity-0 group-hover:opacity-20"
            initial={false}
            animate={{ scale: [1, 2, 1], opacity: [0, 0.2, 0] }}
            transition={{ duration: 2, repeat: Infinity }}
          />
        </svg>
      </motion.div>
    );
  };

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[400px] bg-red-50/10 backdrop-blur-sm rounded-2xl border border-red-500/20">
        <div className="text-center px-6 py-8">
          <div className="text-red-400 text-2xl font-semibold mb-3">
            Unable to load analytics
          </div>
          <div className="text-red-300/80 mb-6">Please try again later</div>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-2 bg-red-500/20 text-red-300 rounded-lg hover:bg-red-500/30 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen bg-slate-900 text-white overflow-hidden px-4 py-8 sm:px-6 sm:py-12">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-cyan-900/20 mix-blend-overlay" />
        <div className="absolute h-full w-full bg-[radial-gradient(#0369a1_1px,transparent_1px)] [background-size:16px_16px] opacity-20" />
      </div>

      {/* Floating Orbs with more complex animations */}
      <motion.div
        animate={{
          y: [0, -20, 0],
          x: [0, 15, 0],
          scale: [1, 1.1, 1],
          rotate: [0, 45, 0],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute -left-20 -top-20 w-60 h-60 bg-cyan-600/30 rounded-full blur-3xl"
      />
      <motion.div
        animate={{
          y: [0, 20, 0],
          x: [0, -15, 0],
          scale: [1, 1.2, 1],
          rotate: [0, -45, 0],
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute -right-20 -bottom-20 w-60 h-60 bg-violet-600/30 rounded-full blur-3xl"
      />

      <div className="relative container mx-auto max-w-7xl">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          className="text-center mb-8 sm:mb-12"
        >
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-black text-cyan-100 tracking-tight mb-3">
            iBird Analytics
          </h1>
          <p className="text-lg text-white/95 font-medium">
            Live Hedera Network Metrics
          </p>
        </motion.div>

        {/* Time Period Selector */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2, ease: "easeOut" }}
          className="relative mb-6 sm:mb-8"
        >
          <div className="absolute inset-0 bg-gradient-to-br from-cyan-500/30 to-blue-500/30 rounded-2xl blur-xl" />
          <div
            className="relative bg-slate-800/50 backdrop-blur-xl border border-white/10 rounded-xl p-2 shadow-2xl 
            hover:shadow-cyan-500/10 hover:border-cyan-500/20 transition-all duration-300"
          >
            <div className="grid grid-cols-3 sm:grid-cols-6 gap-2">
              {periods.map((period, idx) => (
                <motion.button
                  key={period.id}
                  onClick={() => setSelectedPeriod(period.id)}
                  className={`
                    relative px-3 py-2.5 rounded-lg transition-all duration-300
                    ${
                      selectedPeriod === period.id
                        ? "bg-cyan-600 text-white shadow-lg"
                        : "hover:bg-white/10 text-white/90 hover:text-white"
                    }
                  `}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <div className="flex items-center justify-center gap-2">
                    <span className="text-lg">{period.icon}</span>
                    <span className="text-sm font-medium whitespace-nowrap">
                      {period.label}
                    </span>
                  </div>
                  {selectedPeriod === period.id && (
                    <motion.div
                      layoutId="activeIndicator"
                      className="absolute inset-0 bg-gradient-to-br from-cyan-500/30 via-blue-500/30 to-indigo-500/30 rounded-lg -z-10"
                      transition={{
                        type: "spring",
                        bounce: 0.2,
                        duration: 0.6,
                      }}
                    />
                  )}
                </motion.button>
              ))}
            </div>
          </div>
        </motion.div>

        {/* Main Stats */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6 sm:mb-8">
          {mainStats.map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                delay: index * 0.1 + 0.4,
                duration: 0.6,
                ease: "easeOut",
              }}
              className="group relative bg-slate-800/50 backdrop-blur-xl border border-white/10 rounded-xl p-6 
                hover:bg-slate-700/50 hover:border-cyan-500/20 hover:shadow-lg hover:shadow-cyan-500/10 
                transition-all duration-300"
            >
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-cyan-600/15 rounded-xl border border-white/5">
                    <span className="text-2xl">{stat.icon}</span>
                  </div>
                  <div>
                    <h3 className="text-xl font-bold text-cyan-100">
                      {stat.label}
                    </h3>
                    <p className="text-sm text-white/70">{stat.description}</p>
                  </div>
                </div>
                {renderGrowthIndicator(stat.growth)}
              </div>
              <div className="flex items-baseline gap-3">
                <div className="text-4xl font-black text-cyan-100">
                  {loading ? (
                    <div className="h-10 w-32 bg-white/10 rounded animate-pulse" />
                  ) : (
                    stat.value.toLocaleString()
                  )}
                </div>
                <div className="text-sm text-white/50 mt-1">
                  {stat.previousValue > 0 &&
                    `vs ${stat.previousValue.toLocaleString()} previous`}
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Secondary Stats */}
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          {secondaryStats.map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                delay: index * 0.1 + 0.6,
                duration: 0.6,
                ease: "easeOut",
              }}
              className="group relative bg-slate-800/50 backdrop-blur-xl border border-white/10 rounded-xl p-4 
                hover:bg-slate-700/50 hover:border-cyan-500/20 hover:shadow-lg hover:shadow-cyan-500/10 
                transition-all duration-300"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="p-2 bg-cyan-600/15 rounded-lg border border-white/5">
                  <span className="text-xl">{stat.icon}</span>
                </div>
                {renderGrowthIndicator(stat.trend)}
              </div>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <p className="text-sm text-white/70 font-medium">
                    {stat.label}
                  </p>
                  <div className="text-xl font-bold text-cyan-100">
                    {loading ? (
                      <div className="h-6 w-16 bg-white/10 rounded animate-pulse" />
                    ) : (
                      stat.value.toLocaleString()
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-xs text-white/50">
                    {`vs ${stat.previousValue.toLocaleString()}`}
                  </div>
                  {renderSparkline(stat.trend)}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
