import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  FaArrowLeft,
  FaShieldAlt,
  FaUserLock,
  FaExclamationTriangle,
} from "react-icons/fa";

const TermsOfService = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12">
      {/* Background Elements */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 2 }}
        className="absolute inset-0"
        style={{
          backgroundImage:
            "radial-gradient(circle at 50% 50%, #FF6B6B 1px, transparent 1px)",
          backgroundSize: "50px 50px",
        }}
      />

      <div className="container mx-auto px-4 relative mt-6">
        {/* Header */}
        <motion.div {...fadeIn} className="text-center mb-12">
          <h1 className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-orange-500 mb-4">
            Terms of Service
          </h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </motion.div>

        {/* Important Notice */}
        <motion.div
          {...fadeIn}
          className="bg-orange-50 border-l-4 border-orange-500 p-6 rounded-lg mb-8"
        >
          <div className="flex items-start">
            <FaExclamationTriangle className="text-orange-500 text-2xl mr-4 mt-1" />
            <div>
              <h2 className="text-xl font-semibold text-orange-800 mb-2">
                Important Notice
              </h2>
              <p className="text-orange-700">
                Please read these terms carefully before using iAssets services.
                By accessing or using our platform, you agree to be bound by
                these terms. If you disagree with any part of these terms, you
                may not access or use our services.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Main Content */}
        <motion.div
          {...fadeIn}
          className="bg-white rounded-2xl shadow-xl overflow-hidden"
        >
          {/* Table of Contents */}
          <div className="bg-gray-50 p-8 border-b border-gray-200">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              Table of Contents
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              {[
                "1. Acceptance of Terms",
                "2. Eligibility",
                "3. User Content and Conduct",
                "4. User Rights",
                "5. User Obligations",
                "6. Liability for Content",
                "7. Decentralization and Data Storage",
                "8. Privacy and Data Protection",
                "9. Tokenomics and Platform Economy",
                "10. Limitation of Liability",
                "11. Changes to Terms",
                "12. Governing Law",
                "13. Termination",
                "14. Contact Information",
              ].map((item, index) => (
                <motion.a
                  key={index}
                  href={`#${item
                    .split(". ")[1]
                    .toLowerCase()
                    .replace(/ /g, "-")}`}
                  className="text-gray-600 hover:text-purple-600 transition-colors"
                  whileHover={{ x: 5 }}
                >
                  {item}
                </motion.a>
              ))}
            </div>
          </div>

          {/* Terms Content */}
          <div className="p-8 space-y-8">
            {/* Existing sections with enhanced styling */}
            <Section
              id="acceptance-of-terms"
              title="1. Acceptance of Terms"
              content={`Welcome to iAssets. The following terms of service ("Terms") govern your access to and use of our decentralized social media platform ("Platform"), iAssets, iBird, iAssets.org, iBird.io our websites, applications, services, and features thereof (collectively, the "Services").

By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not access or use our Services.`}
            />

            {/* Continue with other sections... */}
            <Section
              id="eligibility"
              title="2. Eligibility"
              content={` You must be at least 18 years old to access or use the Services. By agreeing to these Terms, you represent and warrant that:

 You are of legal age to form a binding contract
• Meet all eligibility requirements
• Have the right, authority, and capacity to accept these Terms
• Are not prohibited from using the Services under any applicable laws
• Will use the Services only for lawful purposes`}
            />

            <Section
              id="user-content-and-conduct"
              title="3. User Content and Conduct"
              content={`All content published on the Platform is the sole responsibility of the person who originated such content. You agree not to:

• Post illegal, harmful, threatening, abusive, or defamatory content
• Impersonate others or provide inaccurate information
• Upload malware or malicious code
• Attempt to gain unauthorized access to the Platform
• Interfere with or disrupt the Services
• Engage in token manipulation or market abuse
• Violate any applicable laws or regulations`}
            />

            <Section
              id="user-rights"
              title="4. User Rights"
              content={`Users retain full ownership rights to their content. By posting content, you grant iAssets a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such content.

You represent and warrant that:

• You own or have the right to use all content you post
• Your content will not violate any third-party rights
• You have obtained all necessary consents and permissions`}
            />

            <Section
              id="user-obligations"
              title="5. User Obligations"
              content={`As a user of the Platform, you agree to:

• Provide accurate and complete information
• Maintain the security of your account
• Not share account credentials
• Report unauthorized use immediately
• Comply with all applicable laws
• Not engage in market manipulation
• Not use the platform for illegal activities
• Keep your wallet credentials secure`}
            />

            <Section
              id="liability-for-content"
              title="6. Liability for Content"
              content={`Users are solely responsible for:

• All content they post or share
• Accuracy of their information
• Consequences of their actions
• Any resulting legal claims
• Third-party content they share
• Links to external websites

iAssets does not endorse or verify user-generated content.`}
            />

            <Section
              id="decentralization-and-data-storage"
              title="7. Decentralization and Data Storage"
              content={`Understanding platform architecture:

• Content is stored on decentralized networks
• Data cannot be deleted once posted
• Information is publicly accessible
• Multiple nodes store content copies
• No central point of control
• Blockchain transactions are permanent

Users acknowledge the immutable nature of blockchain data.`}
            />

            <Section
              id="cryptocurrency-wallets"
              title="8. Cryptocurrency Wallets"
              content={`Users are responsible for:

• Wallet security and management
• Private key protection
• Transaction verification
• Gas fees and network costs
• Understanding blockchain risks
• Backup of recovery phrases
• Compatible wallet selection`}
            />

            <Section
              id="security-measures"
              title="9. Security Measures"
              content={`Required security practices:

• Use strong passwords
• Keep software updated
• Use secure networks
• Report suspicious behavior
• Regular security reviews`}
            />

            <Section
              id="asset-token"
              title="10. ASSET Token"
              content={`Regarding the ASSET token:

• No guaranteed value or returns
• Subject to market volatility
• Used for platform tips
• Trading carries risks
• May have limited liquidity`}
            />

            <Section
              id="privacy-and-data-protection"
              title="11. Privacy and Data Protection"
              content={`Privacy considerations:

• Public blockchain data
• Limited data removal options
• No guaranteed privacy
• Third-party access possible
• Data analysis by others
• Permanent transaction records
• GDPR limitations`}
            />

            <Section
              id="intellectual-property"
              title="12. Intellectual Property"
              content={`IP rights and restrictions:

• Platform content ownership
• User content licensing
• Trademark protection
• Copyright compliance
• Patent rights
• Code protection
• Brand guidelines`}
            />

            {/* Enhanced Cryptocurrency Section */}
            <Section
              id="cryptocurrency-wallets"
              title="6. Cryptocurrency Wallets"
              content={`Users are solely responsible for:

• Securing their private keys and wallet credentials
• All transactions initiated from their wallets
• Understanding the risks of cryptocurrency transactions
• Verifying all transaction details before confirmation
• Any losses resulting from compromised wallet security
• Maintaining backup of wallet recovery phrases

iAssets will never ask for your private keys or recovery phrases.`}
            />

            {/* New Section: Security Measures */}
            <Section
              id="security-measures"
              title="7. Security Measures"
              content={`Users must implement reasonable security measures including:

• Using strong, unique passwords for web3 wallets and accounts
• Enabling hardware wallet security keys when possible
• Not sharing account access
• Reporting suspicious activities immediately
• Maintaining updated software and systems`}
            />

            {/* Enhanced Privacy Section */}
            <Section
              id="privacy-and-data-protection"
              title="8. Privacy and Data Protection"
              content={`Due to the decentralized nature of our platform:

• All data posted is public and immutable
• Content cannot be deleted once posted
• User activity is visible on the blockchain
• Third parties can access and analyze public data
• We cannot guarantee data privacy or confidentiality
• Users in GDPR jurisdictions should not use the platform

Users acknowledge and accept these privacy limitations before using the platform.`}
            />

            {/* New Section: Tokenomics */}
            <Section
              id="tokenomics"
              title="9. Tokenomics and Platform Economy"
              content={`Understanding of platform tokens:

• ASSET tokens have no guaranteed value
• Token prices may be volatile
• Trading carries significant risks
• No guarantees of returns or profits
• Tokens may become illiquid
• Regulatory changes may affect token utility

Users should conduct their own research before engaging with platform tokens.`}
            />

            {/* Additional Sections */}
            <Section
              id="limitation-of-liability"
              title="10. Limitation of Liability"
              content={`iAssets and its affiliates shall not be liable for:

• Any direct, indirect, incidental, special, consequential, or punitive damages
• Loss of profits, revenue, data, or crypto assets
• Business interruption or loss of opportunities
• Any damages resulting from unauthorized access to accounts
• System failures or network interruptions
• Changes in cryptocurrency values or market conditions

The platform is provided "as is" without any warranties, express or implied.`}
            />

            <Section
              id="changes-to-terms"
              title="11. Changes to Terms"
              content={`iAssets reserves the right to modify these Terms at any time:

• Changes will be effective immediately upon posting
• Users will be notified of material changes
• Continued use constitutes acceptance of changes
• Users should regularly review these Terms
• Previous versions may not be available

Your continued use of the Services after changes constitutes acceptance of the modified Terms.`}
            />

            <Section
              id="governing-law"
              title="12. Governing Law"
              content={`These Terms shall be governed by and construed in accordance with:

• iAssets is an open source project not registered in any jurisdiction
• iAssets is a community-driven platform similar to Bitcoin
• No single entity controls or operates iAssets
• Users interact with the platform at their own discretion
• Subject to applicable cryptocurrency regulations in their jurisdiction
• Users are responsible for compliance with their local laws

The decentralized nature of iAssets means no specific governing law or jurisdiction applies. Users are responsible for understanding and following their local regulations.`}
            />

            <Section
              id="termination"
              title="13. Termination"
              content={`iAssets reserves the right to:

• Terminate or suspend access to Services immediately
• Without prior notice or liability
• For any reason whatsoever
• Including breach of these Terms 

Upon termination:

• All rights granted to you will cease
• You must cease all use of the Services
• Content may remain on the blockchain permanently
• Token holdings and NFTs remain your property`}
            />

            <Section
              id="contact-information"
              title="14. Contact Information"
              content={`For questions about these Terms:

• Website: www.iassets.org
• Discord: http://discord.gg/xM7SkkTEAG
• Twitter: @iAssetsOrg

Response times may vary. For urgent matters, please use Discord for faster support.`}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

// Section Component
const Section: React.FC<{
  id: string;
  title: string;
  content: string;
}> = ({ id, title, content }) => (
  <motion.section
    id={id}
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
    className="scroll-mt-20"
  >
    <h2 className="text-2xl font-bold text-gray-800 mb-4">{title}</h2>
    <div className="prose prose-lg max-w-none text-gray-600">
      {content.split("\n\n").map((paragraph, index) => (
        <p key={index} className="mb-4">
          {paragraph.includes("•") ? (
            <ul className="list-disc pl-6 space-y-2">
              {paragraph.split("\n").map((item, i) => (
                <li key={i}>{item.replace("• ", "")}</li>
              ))}
            </ul>
          ) : (
            paragraph
          )}
        </p>
      ))}
    </div>
  </motion.section>
);

export default TermsOfService;
