import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { FaExternalLinkAlt } from "react-icons/fa";

interface TokensPrice {
  nbmPricePancake: string;
  assetPriceCMC: string;
  assetPriceSaucer: string;
}

interface BurnData {
  nbmBurnedPercentage: string;
  assetBurnedPercentage: string;
}

const TokensInfo = () => {
  const [burnData, setBurnData] = useState<BurnData | null>(null);
  const [tokensPrice, setTokensPrice] = useState<TokensPrice | null>(null);

  const ASSET_BURN_PROOF =
    "https://bscscan.com/token/0x6b471d5Ab9f3d92A600e7d49A0b135BF6D4c6A5b?a=0x000000000000000000000000000000000000dead";
  const NBM_BURN_PROOF =
    "https://bscscan.com/token/0x12da2f2761038486271c99da7e0fb4413e2b5e38?a=0x000000000000000000000000000000000000dead";

  useEffect(() => {
    const fetchBurnData = async () => {
      try {
        const response = await axios.get("/burnData.json");
        setBurnData(response.data);
      } catch (error) {
        console.error("Error fetching burn data:", error);
      }
    };

    const fetchTokensPrice = async () => {
      try {
        const response = await axios.get("/tokensPrice.json");
        setTokensPrice(response.data);
      } catch (error) {
        console.error("Error fetching tokens price:", error);
      }
    };

    fetchBurnData();
    fetchTokensPrice();

    // Optional: Refresh data every 5 minutes
    const interval = setInterval(() => {
      fetchBurnData();
      fetchTokensPrice();
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative min-h-screen py-8 bg-[#FDF8F3] overflow-hidden">
      {/* Animated Background */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 2 }}
        className="absolute inset-0"
        style={{
          backgroundImage:
            "radial-gradient(circle at 50% 50%, #FF6B6B 1px, transparent 1px)",
          backgroundSize: "30px 30px",
        }}
      />

      {/* Floating Elements */}
      <motion.div
        animate={{
          y: [0, -20, 0],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute left-4 top-20 w-24 h-24 bg-gradient-to-r from-orange-400/30 to-yellow-400/30 rounded-full blur-xl"
      />
      <motion.div
        animate={{
          y: [0, 20, 0],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute right-4 bottom-20 w-24 h-24 bg-gradient-to-r from-teal-400/30 to-blue-400/30 rounded-full blur-xl"
      />

      <div className="relative z-10 max-w-7xl mx-auto px-4">
        <motion.h1
          className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-orange-500"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Token Information
        </motion.h1>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="space-y-8"
        >
          {/* Tokens Price Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 text-center">
              Tokens Price
            </h2>
            <div className="flex flex-col gap-4 max-w-sm mx-auto">
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  NBM (BSC)
                </h3>
                <p className="text-2xl font-bold text-purple-600 mb-2">
                  ${tokensPrice?.nbmPricePancake}
                </p>
                <p className="text-xs text-gray-600">1000 NBM = 1 ASSET(HTS)</p>
              </motion.div>

              <motion.div
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  ASSET (BSC)
                </h3>
                <p className="text-2xl font-bold text-orange-500 mb-2">
                  ${tokensPrice?.assetPriceCMC}
                </p>
                <p className="text-xs text-gray-600">
                  1 ASSET(BSC) = 1 ASSET(HTS)
                </p>
              </motion.div>

              <motion.div
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  ASSET (HTS)
                </h3>
                <p className="text-2xl font-bold text-teal-600 mb-2">
                  ${tokensPrice?.assetPriceSaucer}
                </p>
              </motion.div>
            </div>
          </section>

          {/* Converted Tokens Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 text-center">
              Converted Tokens
            </h2>
            <div className="flex flex-col gap-4 max-w-sm mx-auto">
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  NBM (BSC)
                </h3>
                <div className="flex items-center justify-between">
                  <p className="text-2xl font-bold text-purple-600">
                    {burnData?.nbmBurnedPercentage}%
                  </p>
                  <motion.a
                    href={NBM_BURN_PROOF}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-sm text-purple-600 hover:text-purple-700"
                    whileHover={{ x: 3 }}
                  >
                    View Proof <FaExternalLinkAlt size={14} />
                  </motion.a>
                </div>
              </motion.div>

              <motion.div
                whileHover={{ scale: 1.02 }}
                className="p-4 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  ASSET (BSC)
                </h3>
                <div className="flex items-center justify-between">
                  <p className="text-2xl font-bold text-orange-500">
                    {burnData?.assetBurnedPercentage}%
                  </p>
                  <motion.a
                    href={ASSET_BURN_PROOF}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-sm text-orange-500 hover:text-orange-600"
                    whileHover={{ x: 3 }}
                  >
                    View Proof <FaExternalLinkAlt size={14} />
                  </motion.a>
                </div>
              </motion.div>
            </div>
          </section>
        </motion.div>
      </div>
    </div>
  );
};

export default TokensInfo;
