import React from "react";
import ConnectButton from "../components/ConnectButton";
import Footer from "../sections/Footer";
import UpgradeTokens from "../components/UpgradeTokens";
import { Web3ModalProvider } from "../components/Web3ModalProvider";
import TokensInfo from "../components/TokensInfo";
import TransactionList from "../components/TransactionList";
import { motion } from "framer-motion";

const ConvertPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-[#FDF8F3]">
      <Web3ModalProvider>
        <div className="relative min-h-screen py-12 sm:py-16 flex flex-col justify-start overflow-hidden">
          {/* Animated Background */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1 }}
            transition={{ duration: 2 }}
            className="absolute inset-0"
            style={{
              backgroundImage:
                "radial-gradient(circle at 50% 50%, #FF6B6B 1px, transparent 1px)",
              backgroundSize: "30px 30px",
            }}
          />

          {/* Floating Elements */}
          <motion.div
            animate={{
              y: [0, -20, 0],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut",
            }}
            className="absolute left-10 top-32 w-32 h-32 bg-gradient-to-r from-orange-400/30 to-yellow-400/30 rounded-full blur-xl"
          />
          <motion.div
            animate={{
              y: [0, 20, 0],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
            className="absolute right-10 bottom-32 w-32 h-32 bg-gradient-to-r from-teal-400/30 to-blue-400/30 rounded-full blur-xl"
          />

          <div className="relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="relative max-w-4xl mx-auto px-4"
            >
              <div className="relative">
                {/* Gradient Border Effect */}
                <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-orange-600 rounded-2xl blur opacity-25"></div>
                <div className="relative bg-white/80 backdrop-blur-sm shadow-xl rounded-xl overflow-hidden">
                  <div className="p-6 sm:p-10">
                    <div className="max-w-3xl mx-auto space-y-8">
                      <div className="space-y-6">
                        <TokensInfo />
                      </div>
                      <div className="pt-6 border-t border-gray-200/50">
                        <div className="text-center mb-6">
                          <ConnectButton />
                        </div>
                        <UpgradeTokens />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Transaction List Section */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
                className="mt-8"
              >
                <TransactionList />
              </motion.div>
            </motion.div>
          </div>
        </div>
      </Web3ModalProvider>
      <Footer />
    </div>
  );
};

export default ConvertPage;
