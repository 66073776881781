import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAccount } from "wagmi";
import { motion } from "framer-motion";
import { FaExternalLinkAlt } from "react-icons/fa";
import { BsCheckCircleFill, BsClockFill } from "react-icons/bs";

interface Event {
  user: string;
  tokenAddress: string;
  token: string;
  hederaAccountId: string;
  amount: string;
  blockNumber: number;
  transactionHash: string;
  convert: boolean;
  convertHash: string;
}

const TransactionList: React.FC = () => {
  const { address, isConnected } = useAccount();
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;
    let intervalId: NodeJS.Timeout;

    const fetchEvents = async () => {
      try {
        const response = await axios.get<Event[]>("/events.json");
        const eventsData = response.data;

        if (isConnected && address) {
          // Filter events by connected wallet address and sort in reverse order
          const userEvents = eventsData
            .filter(
              (event) => event.user.toLowerCase() === address.toLowerCase()
            )
            .sort((a, b) => b.blockNumber - a.blockNumber); // Sort by blockNumber in descending order

          if (isMounted) {
            setEvents(userEvents);
          }
        } else if (isMounted) {
          setEvents([]);
        }
      } catch (error) {
        console.error("Error fetching events data:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchEvents();
    intervalId = setInterval(fetchEvents, 5 * 60 * 1000);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isConnected, address]);

  if (!isConnected) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center text-blue-600 my-6 p-4 bg-blue-50 rounded-xl shadow-sm"
      >
        Connect your wallet to view your transactions.
      </motion.div>
    );
  }

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="text-center text-gray-600 my-6 p-4 bg-gray-50 rounded-xl shadow-sm"
      >
        Loading transactions...
      </motion.div>
    );
  }

  return (
    <div className="relative py-8 bg-[#FDF8F3] overflow-hidden">
      {/* Animated Background */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 2 }}
        className="absolute inset-0"
        style={{
          backgroundImage:
            "radial-gradient(circle at 50% 50%, #FF6B6B 1px, transparent 1px)",
          backgroundSize: "30px 30px",
        }}
      />

      {/* Floating Elements */}
      <motion.div
        animate={{
          y: [0, -20, 0],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute left-4 top-20 w-24 h-24 bg-gradient-to-r from-orange-400/30 to-yellow-400/30 rounded-full blur-xl"
      />
      <motion.div
        animate={{
          y: [0, 20, 0],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute right-4 bottom-20 w-24 h-24 bg-gradient-to-r from-teal-400/30 to-blue-400/30 rounded-full blur-xl"
      />

      <div className="relative z-10 max-w-3xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg p-6"
        >
          <h2 className="text-2xl font-bold mb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-orange-500">
            Your Transactions
          </h2>
          <p className="text-sm text-gray-600 mb-6 text-center">
            Note: Takes 20 minutes for the transaction to be shown here after it
            has been confirmed on BSC.
          </p>

          <div className="overflow-y-auto max-h-[500px] pr-2 space-y-4">
            {events.length === 0 ? (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-center text-gray-500 py-8"
              >
                No transactions found for your wallet.
              </motion.p>
            ) : (
              <motion.ul
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ staggerChildren: 0.1 }}
                className="space-y-4"
              >
                {events.map((event) => (
                  <motion.li
                    key={event.transactionHash}
                    whileHover={{ scale: 1.01 }}
                    className="p-4 bg-white rounded-lg shadow-sm border border-gray-100 transition-all duration-300"
                  >
                    <div className="flex items-center gap-2 mb-3">
                      {event.convert ? (
                        <>
                          <BsCheckCircleFill className="text-green-500 text-lg" />
                          <span className="text-green-600 font-medium">
                            Success
                          </span>
                        </>
                      ) : (
                        <>
                          <BsClockFill className="text-orange-500 text-lg" />
                          <span className="text-orange-600 font-medium">
                            Pending...
                          </span>
                        </>
                      )}
                    </div>

                    <div className="space-y-2 text-sm">
                      <div className="flex justify-between items-center">
                        <span className="text-gray-600 font-medium">Token</span>
                        <span className="text-gray-800">{event.token}</span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="text-gray-600 font-medium">
                          Exchange Rate
                        </span>
                        <span className="text-gray-800">
                          {event.token === "ASSET"
                            ? "1 ASSET(BSC) = 1 ASSET(HTS)"
                            : "1000 NBM(BSC) = 1 ASSET(HTS)"}
                        </span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="text-gray-600 font-medium">
                          Amount
                        </span>
                        <span className="text-gray-800">{event.amount}</span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="text-gray-600 font-medium">
                          Hedera Account
                        </span>
                        <span className="text-gray-800">
                          {event.hederaAccountId}
                        </span>
                      </div>

                      <div className="pt-2 space-y-2">
                        <motion.a
                          whileHover={{ x: 3 }}
                          href={`https://bscscan.com/tx/${event.transactionHash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-between text-purple-600 hover:text-purple-700"
                        >
                          <span className="font-medium">BSC Hash</span>
                          <div className="flex items-center gap-1">
                            <span className="text-xs truncate max-w-[180px]">
                              {event.transactionHash}
                            </span>
                            <FaExternalLinkAlt size={12} />
                          </div>
                        </motion.a>

                        {event.convert && (
                          <motion.a
                            whileHover={{ x: 3 }}
                            href={`https://hashscan.io/mainnet/transaction/${event.convertHash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-between text-orange-500 hover:text-orange-600"
                          >
                            <span className="font-medium">HTS Hash</span>
                            <div className="flex items-center gap-1">
                              <span className="text-xs truncate max-w-[180px]">
                                {event.convertHash}
                              </span>
                              <FaExternalLinkAlt size={12} />
                            </div>
                          </motion.a>
                        )}
                      </div>
                    </div>
                  </motion.li>
                ))}
              </motion.ul>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default TransactionList;
