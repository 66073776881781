import React, { useState, useEffect } from "react";
import {
  useAccount,
  useWriteContract,
  useReadContract,
  useBalance,
  useWaitForTransactionReceipt,
} from "wagmi";
import { parseUnits, Address } from "viem";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WalletInfo from "./WalletInfo";
import { motion } from "framer-motion";
import {
  BsCheckCircleFill,
  BsClockFill,
  BsExclamationCircleFill,
} from "react-icons/bs";

////////////////////////////////////////////////////////////////////
// ABI definitions for the main contract and ERC20 tokens
const abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "hederaAccountId",
        type: "string",
      },
    ],
    name: "TokensBurned",
    type: "event",
  },
  {
    inputs: [],
    name: "ASSET",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "BURN_ADDRESS",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "NBM",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "burnHistories",
    outputs: [
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "hederaAccountId",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "hederaAccountId",
        type: "string",
      },
    ],
    name: "burnTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getBurnHistory",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "string",
            name: "hederaAccountId",
            type: "string",
          },
        ],
        internalType: "struct TokenBurner.BurnInfo[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const erc20Abi = [
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_from",
        type: "address",
      },
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
      {
        name: "_spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
];

// Contract and token addresses
const CONTRACT_ADDRESS = "0x0DF5C97F409147aEb0c7A958b6a3D64B67d0EaA1";
const ASSET_ADDRESS = "0x6b471d5Ab9f3d92A600e7d49A0b135BF6D4c6A5b";
const NBM_ADDRESS = "0x12Da2f2761038486271C99DA7e0FB4413e2B5E38";
const ASSET_DECIMALS = 18;
const NBM_DECIMALS = 9;

////////////////////////////////////////////////////////////////////
const UpgradeTokens: React.FC = () => {
  ////////////////////////////////////////////////////////////////////
  // Hooks for account connection and balance
  const { isConnected, address } = useAccount();
  const { data: assetBalance } = useBalance({ address, token: ASSET_ADDRESS });
  const { data: nbmBalance } = useBalance({ address, token: NBM_ADDRESS });
  ////////////////////////////////////////////////////////////////////
  // State variables
  const [tokenAddress, setTokenAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [hederaAccountId, setHederaAccountId] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isAssociated, setIsAssociated] = useState(false);
  const [isCheckingAssociation, setIsCheckingAssociation] = useState(false);
  const [assetAllowance, setAssetAllowance] = useState<bigint>(BigInt(0));
  const [nbmAllowance, setNbmAllowance] = useState<bigint>(BigInt(0));
  const [allowanceStatus, setAllowanceStatus] = useState<
    "unchecked" | "checking" | "insufficient" | "sufficient" | "approving"
  >("unchecked");
  const [approvalHash, setApprovalHash] = useState<`0x${string}` | undefined>(
    undefined
  );
  ////////////////////////////////////////////////////////////////////
  // Read contract hooks for allowances
  //@ts-ignore
  const { data: assetAllowanceData } = useReadContract({
    address: ASSET_ADDRESS as Address,
    abi: erc20Abi,
    functionName: "allowance",
    args: [address as Address, CONTRACT_ADDRESS as Address],
  });
  ////////////////////////////////////////////////////////////////////
  //@ts-ignore
  const { data: nbmAllowanceData } = useReadContract({
    address: NBM_ADDRESS as Address,
    abi: erc20Abi,
    functionName: "allowance",
    args: [address as Address, CONTRACT_ADDRESS as Address],
  });
  ////////////////////////////////////////////////////////////////////
  // Function to fetch and update allowances
  const fetchAllowances = () => {
    if (isConnected && address) {
      setAssetAllowance(
        BigInt((assetAllowanceData as bigint)?.toString() || "0")
      );
      setNbmAllowance(BigInt((nbmAllowanceData as bigint)?.toString() || "0"));
    }
  };
  ////////////////////////////////////////////////////////////////////
  // Effect to fetch allowances when connected or allowance data changes
  useEffect(() => {
    fetchAllowances();
  }, [isConnected, address, assetAllowanceData, nbmAllowanceData]);
  ////////////////////////////////////////////////////////////////////

  // Effect to set initial token and amount based on balances
  useEffect(() => {
    if (assetBalance && Number(assetBalance.value) > 0) {
      setTokenAddress(ASSET_ADDRESS);
      setAmount(assetBalance.formatted);
    } else if (nbmBalance && Number(nbmBalance.value) > 0) {
      setTokenAddress(NBM_ADDRESS);
      setAmount(nbmBalance.formatted);
    } else {
      setTokenAddress("");
      setAmount("");
    }
  }, [assetBalance, nbmBalance]);
  ////////////////////////////////////////////////////////////////////
  // Handler for token selection change
  const handleTokenChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedToken = e.target.value;
    setTokenAddress(selectedToken);
    if (selectedToken === ASSET_ADDRESS && assetBalance) {
      setAmount(assetBalance.formatted);
    } else if (selectedToken === NBM_ADDRESS && nbmBalance) {
      setAmount(nbmBalance.formatted);
    } else {
      setAmount("");
    }
    setAllowanceStatus("unchecked");
    setApprovalHash(undefined);
    setIsAssociated(false);
    setHederaAccountId("");
  };
  ////////////////////////////////////////////////////////////////////
  // Function to check token association with Hedera account
  const checkAssociation = async () => {
    if (!hederaAccountId) {
      setError("Please enter your Hedera Account ID.");
      return;
    }
    setIsCheckingAssociation(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://mainnet-public.mirrornode.hedera.com/api/v1/accounts/${hederaAccountId}`
      );
      const tokens = response.data.balance.tokens || [];
      const associated = tokens.some(
        (token: any) => token.token_id === "0.0.1991880"
      );
      setIsAssociated(associated);
      if (!associated) {
        setError("Token is not associated with your Hedera account.");
      } else {
        setError(null);
      }
    } catch (error) {
      console.error("Error checking association:", error);
      setError("Failed to check token association. Please try again.");
      setIsAssociated(false);
    } finally {
      setIsCheckingAssociation(false);
    }
  };
  ////////////////////////////////////////////////////////////////////
  // Write contract hook for token upgrade
  //@ts-ignore
  const {
    data: upgradeHash,
    error: upgradeError,
    isPending: isUpgradePending,
    writeContract: upgradeContract,
  } = useWriteContract();

  // Function to handle token upgrade
  const handleUpgrade = async () => {
    if (!tokenAddress || !amount || !hederaAccountId) {
      setError("Please fill in all fields.");
      return;
    }

    setError(null);

    const decimals =
      tokenAddress === ASSET_ADDRESS ? ASSET_DECIMALS : NBM_DECIMALS;
    const amountInWei = parseUnits(amount, decimals);

    try {
      //@ts-ignore
      await upgradeContract({
        address: CONTRACT_ADDRESS,
        abi,
        functionName: "burnTokens",
        args: [tokenAddress, amountInWei, hederaAccountId],
      });
    } catch (error) {
      console.error("Error upgrading tokens:", error);
      setError("Failed to upgrade tokens. Please try again.");

      // Handle upgradeError
      if (upgradeError) {
        console.error("Upgrade error details:", upgradeError);
        setError(`Upgrade Error: ${upgradeError.message}`);
      }
    }
  };
  ////////////////////////////////////////////////////////////////////
  // Hook to wait for upgrade transaction confirmation
  const { isLoading: isUpgradeConfirming, isSuccess: isUpgradeConfirmed } =
    useWaitForTransactionReceipt({
      hash: upgradeHash,
    });
  ////////////////////////////////////////////////////////////////////

  // Write contract hooks for token approvals
  const {
    data: approveHash,
    error: approveError,
    isPending: isApprovalPending,
    writeContract: approve,
  } = useWriteContract();

  const handleApprove = async () => {
    if (!tokenAddress || !amount) {
      setError("Please fill in all fields.");
      return;
    }

    setError(null);

    const decimals =
      tokenAddress === ASSET_ADDRESS ? ASSET_DECIMALS : NBM_DECIMALS;
    const amountInWei = parseUnits(amount, decimals);

    try {
      //@ts-ignore
      await approve({
        address: tokenAddress as Address,
        abi: erc20Abi,
        functionName: "approve",
        args: [CONTRACT_ADDRESS, amountInWei],
      });
    } catch (error) {
      console.error("Error approving allowance:", error);
      setError("Failed to approve allowance. Please try again.");

      // Handle approveError
      if (approveError) {
        console.error("Approval error details:", approveError);
        setError(`Approval Error: ${approveError.message}`);
      }
    }
  };
  ////////////////////////////////////////////////////////////////////
  // Hook to wait for approve transaction confirmation
  const { isLoading: isApprovalConfirming, isSuccess: isApprovalConfirmed } =
    useWaitForTransactionReceipt({
      hash: approveHash,
    });

  useEffect(() => {
    if (isApprovalConfirmed) {
      setAllowanceStatus("sufficient");
      fetchAllowances();
    }
  }, [isApprovalConfirmed]);
  ////////////////////////////////////////////////////////////////////
  // Function to check allowance
  const checkAllowance = async () => {
    if (!tokenAddress || !amount) return;
    setAllowanceStatus("checking");
    try {
      const decimals =
        tokenAddress === ASSET_ADDRESS ? ASSET_DECIMALS : NBM_DECIMALS;
      const requiredAmount = parseUnits(amount, decimals);

      const currentAllowance =
        tokenAddress === ASSET_ADDRESS ? assetAllowance : nbmAllowance;

      if (currentAllowance >= requiredAmount) {
        setAllowanceStatus("sufficient");
      } else {
        setAllowanceStatus("insufficient");
      }
    } catch (error) {
      console.error("Error checking allowance:", error);
      setAllowanceStatus("unchecked");
    }
  };

  // Effect to check allowance when relevant state changes
  useEffect(() => {
    checkAllowance();
  }, [assetAllowance, nbmAllowance, amount, tokenAddress]);
  ////////////////////////////////////////////////////////////////////

  if (tokenAddress) {
    <WalletInfo />;
  }
  // Render function
  if (!isConnected)
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center text-blue-600 my-6 p-4 bg-blue-50 rounded-xl shadow-sm"
      >
        Connect your wallet to upgrade your tokens.
      </motion.div>
    );

  if (!tokenAddress)
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center text-blue-600 my-6 p-4 bg-blue-50 rounded-xl shadow-sm"
      >
        You don't have tokens to upgrade.
      </motion.div>
    );

  if (isUpgradeConfirmed)
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="fixed inset-0 z-50 flex items-start justify-center bg-black/50 backdrop-blur-sm pt-20"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0, y: -20 }}
          animate={{ scale: 1, opacity: 1, y: 0 }}
          className="bg-white p-6 rounded-xl shadow-xl text-center max-w-[90%] w-[320px] mx-auto"
        >
          <div className="mb-3 text-green-500">
            <BsCheckCircleFill size={40} className="mx-auto" />
          </div>
          <h2 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-teal-500">
            Success!
          </h2>
          <p className="mb-4 text-sm text-gray-600">
            Your will get your ASSET(HTS) tokens in less than 24 hours.
          </p>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => {
              window.location.reload();
            }}
            className="bg-gradient-to-r from-green-500 to-teal-500 text-white font-medium text-sm py-2.5 px-5 rounded-lg transition duration-300 ease-in-out shadow-md hover:shadow-lg"
          >
            OK
          </motion.button>
        </motion.div>
      </motion.div>
    );

  return (
    <div className="relative py-6 bg-[#FDF8F3] overflow-hidden">
      {/* Animated Background */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 2 }}
        className="absolute inset-0"
        style={{
          backgroundImage:
            "radial-gradient(circle at 50% 50%, #FF6B6B 1px, transparent 1px)",
          backgroundSize: "30px 30px",
        }}
      />

      {/* Floating Elements */}
      <motion.div
        animate={{
          y: [0, -20, 0],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute left-4 top-20 w-24 h-24 bg-gradient-to-r from-orange-400/30 to-yellow-400/30 rounded-full blur-xl"
      />
      <motion.div
        animate={{
          y: [0, 20, 0],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute right-4 bottom-20 w-24 h-24 bg-gradient-to-r from-teal-400/30 to-blue-400/30 rounded-full blur-xl"
      />

      <div className="relative z-10 max-w-md mx-auto px-3">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg p-4 sm:p-6"
        >
          <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-orange-500">
            Upgrade Your Tokens
          </h2>

          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center gap-2 text-xs sm:text-sm text-red-500 bg-red-50 p-2.5 rounded-lg mb-4"
            >
              <BsExclamationCircleFill />
              <p>{error}</p>
            </motion.div>
          )}

          <div className="space-y-4 sm:space-y-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <label className="block text-gray-700 text-xs sm:text-sm font-medium mb-1.5">
                Select Token
              </label>
              <select
                value={tokenAddress}
                onChange={handleTokenChange}
                className="w-full bg-white border border-gray-200 rounded-lg p-2.5 text-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-200"
              >
                <option value="" disabled>
                  Select Token
                </option>
                {Number(assetBalance?.value) > 0 && (
                  <option value={ASSET_ADDRESS}>ASSET</option>
                )}
                {Number(nbmBalance?.value) > 0 && (
                  <option value={NBM_ADDRESS}>NBM</option>
                )}
              </select>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <label className="block text-gray-700 text-xs sm:text-sm font-medium mb-1.5">
                Amount
              </label>
              <input
                type="text"
                value={amount}
                readOnly
                className="w-full bg-gray-50 border border-gray-200 rounded-lg p-2.5 text-sm text-gray-600"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="bg-white rounded-lg p-3 sm:p-4 border border-gray-100 shadow-sm"
            >
              <h3 className="font-bold text-gray-800 text-sm sm:text-base mb-2 sm:mb-3 flex items-center gap-2">
                <span className="bg-purple-100 text-purple-600 w-5 h-5 sm:w-6 sm:h-6 rounded-full flex items-center justify-center text-xs sm:text-sm">
                  1
                </span>
                Associate Token
              </h3>
              {!isAssociated ? (
                <>
                  <p className="text-xs sm:text-sm text-gray-600 mb-3">
                    Please associate ASSET TOKEN (0.0.1991880) with your Hedera
                    account.
                  </p>
                  <input
                    type="text"
                    value={hederaAccountId}
                    onChange={(e) => setHederaAccountId(e.target.value)}
                    placeholder="Enter Hedera Account ID"
                    className="w-full bg-white border border-gray-200 rounded-lg p-2.5 text-sm mb-3 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-200"
                  />
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={checkAssociation}
                    disabled={isCheckingAssociation}
                    className={`w-full font-medium py-2.5 px-4 rounded-lg ${
                      isCheckingAssociation
                        ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                        : "bg-gradient-to-r from-purple-500 to-purple-600 text-white shadow-md hover:shadow-lg"
                    } transition-all duration-200`}
                  >
                    {isCheckingAssociation ? (
                      <span className="flex items-center justify-center gap-2">
                        <BsClockFill className="animate-spin" />
                        Checking...
                      </span>
                    ) : (
                      "Check Association"
                    )}
                  </motion.button>
                  <p className="text-center mt-3 text-sm text-gray-600">
                    Need help?{" "}
                    <a
                      target="_blank"
                      className="text-purple-600 hover:text-purple-700 font-medium"
                      href="https://medium.com/@elastum/hedera-how-to-associate-your-tokens-c7021c96cc25"
                    >
                      How to Associate Tokens
                    </a>
                  </p>
                </>
              ) : (
                <div className="flex items-center gap-2 text-green-500 text-sm">
                  <BsCheckCircleFill />
                  <p>Token is associated!</p>
                </div>
              )}
            </motion.div>

            {isAssociated && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7 }}
                className="bg-white rounded-lg p-3 sm:p-4 border border-gray-100 shadow-sm"
              >
                <h3 className="font-bold text-gray-800 text-sm sm:text-base mb-2 sm:mb-3 flex items-center gap-2">
                  <span className="bg-purple-100 text-purple-600 w-5 h-5 sm:w-6 sm:h-6 rounded-full flex items-center justify-center text-xs sm:text-sm">
                    2
                  </span>
                  Allowance
                </h3>
                {allowanceStatus === "checking" && (
                  <div className="flex items-center gap-2 text-blue-500">
                    <BsClockFill className="animate-spin" />
                    <p>Checking allowance...</p>
                  </div>
                )}
                {allowanceStatus === "insufficient" && (
                  <div className="flex items-center gap-2 text-orange-500 mb-3">
                    <BsExclamationCircleFill />
                    <p>Insufficient allowance. Please approve to proceed.</p>
                  </div>
                )}
                {allowanceStatus === "sufficient" && (
                  <div className="flex items-center gap-2 text-green-500">
                    <BsCheckCircleFill />
                    <p>Allowance is sufficient!</p>
                  </div>
                )}

                {allowanceStatus !== "sufficient" && (
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleApprove}
                    disabled={isApprovalPending || isApprovalConfirming}
                    className={`w-full font-medium py-2.5 px-4 rounded-lg ${
                      isApprovalPending || isApprovalConfirming
                        ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                        : "bg-gradient-to-r from-green-500 to-teal-500 text-white shadow-md hover:shadow-lg"
                    } transition-all duration-200`}
                  >
                    {isApprovalPending || isApprovalConfirming ? (
                      <span className="flex items-center justify-center gap-2">
                        <BsClockFill className="animate-spin" />
                        Approving...
                      </span>
                    ) : (
                      "Approve Allowance"
                    )}
                  </motion.button>
                )}
              </motion.div>
            )}

            {isAssociated && allowanceStatus === "sufficient" && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
                className="bg-white rounded-lg p-3 sm:p-4 border border-gray-100 shadow-sm"
              >
                <h3 className="font-bold text-gray-800 text-sm sm:text-base mb-2 sm:mb-3 flex items-center gap-2">
                  <span className="bg-purple-100 text-purple-600 w-5 h-5 sm:w-6 sm:h-6 rounded-full flex items-center justify-center text-xs sm:text-sm">
                    3
                  </span>
                  Upgrade Tokens
                </h3>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleUpgrade}
                  disabled={isUpgradePending || isUpgradeConfirming}
                  className={`w-full font-medium py-2.5 px-4 rounded-lg ${
                    isUpgradePending || isUpgradeConfirming
                      ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                      : "bg-gradient-to-r from-purple-500 to-orange-500 text-white shadow-md hover:shadow-lg"
                  } transition-all duration-200`}
                >
                  {isUpgradePending || isUpgradeConfirming ? (
                    <span className="flex items-center justify-center gap-2">
                      <BsClockFill className="animate-spin" />
                      Upgrading...
                    </span>
                  ) : (
                    "Upgrade Tokens"
                  )}
                </motion.button>
              </motion.div>
            )}
          </div>
        </motion.div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default UpgradeTokens;
