import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaHome } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo-500px-transparent.png";

const Menu: React.FC = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const menuItems = [
    {
      path: "/convert",
      label: "Update Tokens",
      showOn: ["/"],
      className:
        "group inline-flex items-center px-6 py-2 text-white bg-gradient-to-r from-orange-500 to-pink-500 rounded-full hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-orange-500/25",
    },
    {
      path: "/",
      icon: <FaHome />,
      label: "Home",
      showOn: ["/convert", "/TermsOfService"],
      className:
        "inline-flex items-center px-6 py-2 text-gray-700 bg-white rounded-full hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-gray-300/50 border-2 border-gray-100",
    },
  ];

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-white/80 backdrop-blur-lg shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo Section */}
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ scale: 1.05 }}
          >
            <Link to="/" className="flex items-center">
              <motion.img
                src={logo}
                alt="iAssets Logo"
                className="w-16 h-16"
                whileHover={{ scale: 1.4 }}
                transition={{ duration: 0.3 }}
              />
              <motion.span
                className={`text-xl font-bold ${
                  isScrolled ? "text-gray-800" : "text-gray-900"
                }`}
              >
                iAssets
              </motion.span>
            </Link>
          </motion.div>

          {/* Navigation Links */}
          <div className="flex items-center space-x-4">
            {menuItems.map(
              (item) =>
                item.showOn.includes(location.pathname) && (
                  <motion.div
                    key={item.path}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link to={item.path} className={item.className}>
                      {item.icon && <span className="mr-2">{item.icon}</span>}
                      {item.label}
                      {item.path === "/convert" && (
                        <motion.span
                          className="ml-2"
                          animate={{ x: [0, 5, 0] }}
                          transition={{ duration: 1.5, repeat: Infinity }}
                        >
                          →
                        </motion.span>
                      )}
                    </Link>
                  </motion.div>
                )
            )}
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

export default Menu;
